@use "./partials/breakpointMixin" as *;

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Titillium+Web:wght@300;400;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Open Sans", "Titillium Web", sans-serif;
  // 'Open Sans'
}
