@use "../../partials/breakpointMixin" as *;

.hero {
  // background-image: url("../../assets/image/hector-j-rivas-1FxMET2U5dU-unsplash.jpg");
  background-image: url("../../assets/image/ari-spada-wVoMvN5NsU0-unsplash.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;

  width: 100vw;
  // height: 94vh;
  color: #000;
  text-align: center;

  p {
    font-weight: 700;
  }

  .skills-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 75vw;
    margin: auto;
    max-width: 1050px;
  }
}

@include mobile {
  .hero {
    .profile {
      &-title {
        font-size: calc(100vw * 0.12);
        padding-top: 60px;
      }
      &-desc {
        font-size: calc(100vw * 0.053125);
        // padding: 0px 0 80px calc(100vw * 0.15);
        padding-bottom: 25px;
      }
    }

    .skills {
      &-title {
        // padding-left: calc(100vw * 0.15);
        height: calc(100vw * 0.0444);
      }
      &-container {
        div {
          margin: 10px 0;
        }
      }
    }
  }
}
