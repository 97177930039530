@use "../../partials/breakpointMixin" as *;

.coffeeIsGood {
  width: 100%;
  height: auto;
}

.aboutMeTitle {
  margin-top: 25px;
  margin-bottom: 0px;
}

@include desk {
  #aboutMeSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1980px;
    margin: auto;

    .coffeeIsGood,
    .aboutMe {
      width: 40vw;
      max-width: 792px;
    }

    .coffeeIsGood {
      min-height: 340px;
      margin: 15px 0;
    }

    .aboutMeTitle {
      margin-top: 0px;
      margin-bottom: 0.5rem;
    }
  }
}
