@use "../../partials/breakpointMixin" as *;

.projectBackground {
  background-color: #1d1e68;
  color: #fff;
}

.brainslack {
  background-image: url("../../assets/image/BrainSlack.png");
  background-size: contain;
}

.twitterClone {
  background-image: url("../../assets/image/TwitterClone.png");
  background-size: contain;
}

.card {
  margin-bottom: 20px;
  border-radius: 23px;
  border: 0px;
  box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05),
    0 15px 40px rgba(255, 255, 255, 0.2);

  &-body {
    color: #000000;
  }
}

.projects-title {
  margin-top: 20px;
}

.projectCard {
  width: 100%;
  height: 225px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &--title,
  &--techTitle {
    font-weight: 600;
  }

  &--techTitle {
    color: #000000;
  }

  &__tech {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &--span {
      font-size: 12px;
    }
  }
}

span {
  background-color: #5255ff;
  border-radius: 50px;
  padding: 5px;
  margin: 5px;
  font-weight: 700;
}

.projectsImage {
  width: 100%;
  height: auto;
}

@include desk {
  #projectsSection {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    max-width: 1980px;
    margin: auto;

    .projectsImage,
    .projects {
      width: 40vw;
      max-width: 792px;
    }

    .projectsImage {
      min-height: 340px;
      margin: 15px 0;
    }
  }
}
