@use "../../partials/breakpointMixin" as *;

.phone {
  width: 100%;
  height: auto;
}

.contactMeTitle {
  margin-top: 20px;
}

@include desk {
  #ContactSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1980px;
    margin: auto;

    .contactMeTitle {
      margin-top: 0px;
    }

    .phone,
    .contactMe {
      width: 40vw;
      max-width: 792px;
    }

    .phone {
      min-height: 340px;
      margin: 15px 0;
    }
  }
}
