.footerBackground {
  background-color: #1d1e68;
  color: #fff;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  padding-left: 0;
  margin-bottom: 0;
  max-width: 1980px;
  margin: auto;

  a {
    text-decoration: none;
    color: #fff;
  }
  .icon {
    width: 35px;
    height: 35px;
  }
}
