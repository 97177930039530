//making media queries at each break point of the brainFlix
@mixin mobile {
  @media (width < 480px) {
    @content;
  }
}

@mixin tablet {
  @media (width >= 480px) and (width < 768px) {
    @content;
  }
}

@mixin desk {
  @media (width >= 768px) {
    @content;
  }
}

//removing h1-h4 that belong depending on the bandFlix's width
@include mobile {
  .only-tablet-desk {
    display: none !important;
  }
}

@include tablet {
  .only-mobile {
    display: none !important;
  }
  .hamburger-react {
    display: none !important;
  }
}

@include desk {
  .only-mobile {
    display: none !important;
  }
  .hamburger-react {
    display: none !important;
  }
}
