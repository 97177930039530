@use "../../partials/breakpointMixin" as *;

.navBackground {
  background-color: #1d1e68;
  color: #fff;
  position: sticky;
  z-index: 100;
  top: 0;

  .nav {
    max-width: 1980px;
    margin: auto;
  }
}

ul {
  list-style: none;
  a {
    text-decoration: none;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    @media (width < 528px) {
      font-size: 17px;
    }
    li {
      padding: 0px 8px;
    }
  }
}

.nav--left {
  margin-bottom: 0px;
}

.nav__list.only-tablet-desk {
  width: auto;
  margin-bottom: 0px;

  .list {
    &:hover {
      background-color: #fff;
      border-radius: 10px;
      color: #1d1e68;
    }
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;

  background-color: #1d1e68;
  color: #fff;

  &__list {
    display: flex;
    justify-content: space-between;
    width: 300px;
    align-items: center;
  }
}

@include mobile {
  #fade-in {
    width: 0px;
    transition: all 0.75s ease;
    opacity: 0;
    overflow: hidden;
  }

  #fade-in.show {
    width: 150px;
    opacity: 1;
  }

  .nav__list-mobile {
    position: absolute;
    top: 62px;
    right: 0;
    background-color: #081223;
    padding: 0.5rem;

    a {
      & .list {
        text-align: left;
        margin: 5px 0;
        &:hover {
          background-color: #2e6cb4;
          color: #fff;
          border-radius: 10px;
        }
      }
    }
  }

  .hamburger-react {
    height: 44px !important;

    div {
      height: 2px !important;
    }
  }

  #skillsSection,
  #aboutMeSection,
  #projectsSection,
  #ContactSection {
    scroll-margin-top: 7vh;
  }
}
