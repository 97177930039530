@use "../../partials/breakpointMixin" as *;

.icon {
  width: 12.5vw;
  height: 12.5vw;
  max-width: 146px;
  max-height: 146px;
}

@include mobile {
  .icon {
    width: calc(100vw * 0.2288888);
    height: 96px;

    &--name {
      color: #000;
      text-align: center;
    }
  }

  .skill {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
